$fa-font-path: "/fonts";

// overrides here

@import "../../node_modules/bulma/sass/utilities/initial-variables";

$tablet: 768px;

// color pallette
@import "./colors";

$body-size: 15px;

// Typography
$title-weight: $weight-normal;
$subtitle-negative-margin: 0;

// Fonts
@font-face {
  font-family: "Lora";
  src: url("../../public/fonts/Lora-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  src: url("../../public/fonts/OpenSans-Regular.ttf");
}
$family-secondary: "Lora", serif;
$family-primary: "Open Sans", sans-serif;

//spacing - allows us to use shorthands for padding and margins
// ex: mt-30 (margin-top: 30px) p-10 (padding: 10px)
$spacing-values: (
  "small": 10px,
  "medium": 20px,
  "large": 30px,
  "0": 0px,
  "1": 1px,
  "2": 2px,
  "3": 3px,
  "4": 4px,
  "5": 5px,
  "6": 6px,
  "7": 7px,
  "8": 8px,
  "9": 9px,
  "10": 10px,
  "11": 11px,
  "12": 12px,
  "13": 13px,
  "14": 14px,
  "15": 15px,
  "16": 16px,
  "17": 17px,
  "18": 18px,
  "19": 19px,
  "20": 20px,
  "21": 21px,
  "22": 22px,
  "23": 23px,
  "24": 24px,
  "25": 25px,
  "26": 26px,
  "27": 27px,
  "28": 28px,
  "29": 29px,
  "30": 30px,
  "31": 31px,
  "32": 32px,
  "33": 33px,
  "34": 34px,
  "35": 35px,
  "36": 36px,
  "37": 37px,
  "38": 38px,
  "39": 39px,
  "40": 40px,
);

// expose these vars to React
:export {
  dark: $dark;
}
:export {
  light: $light;
}
:export {
  grey: $grey;
}
:export {
  familyPrimary: $family-primary;
}
:export {
  familySecondary: $family-secondary;
}

// default color overrides
$background: $white;
$text: $dark;
$title-color: $dark;
$link: $text;
$link-hover: $black;
$navbar-item-color: $dark;
$navbar-background-color: $white;
$navbar-breakpoint: 975px;
$checkradio-focus: 1px dotted $dark;

// layout customizations
$column-gap: 1rem;
$box-padding: 1rem;
$box-radius: 2px;
$box-shadow:
  0 0 0 $grey,
  0 0 0 1px $grey;
$steps-active-color: $success;
$steps-completed-color: $success;
$steps-maker-default-color: $grey;
$section-padding: 10px 10px;
$section-padding-desktop: 10px 10px;

//navbar
$navbar-item-img-max-height: 3rem;
$navbar-item-active-background-color: $grey;

// form element customizations
$button-padding-horizontal: 1rem;
$button-hover-border-color: black;

// modals
$modal-card-head-background-color: $grey-light;
$modal-content-width: 900px;
$modal-z: 9998;

@import "../../node_modules/bulma/bulma.sass";
@import "./checkradio.sass";
//@import "../../../../node_modules/bulma-extensions/bulma-timeline/dist/css/bulma-timeline.sass";
@import "../../node_modules/bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.sass";
@import "../../node_modules/bulma-extensions/bulma-steps/dist/css/bulma-steps.sass";

// bulma class overrides

html {
  overflow-y: auto;
}

.button {
  height: auto;
  border-radius: 2px;
}

.container {
  max-width: 1152px;
}

.input,
.textarea,
.label,
.checkbox,
.radio,
select,
label {
  color: $dark;
}

.select select:hover {
  border-color: $border;
}

.has-background-dark {
  color: $white;
}
.has-background-dark.button:hover {
  color: $grey;
}

.has-background-blue-30 {
  background-color: $light-blue-30;
}

.has-background-black-5 {
  background-color: $black-5;
}

.has-background-black-10 {
  background-color: $black-10;
}

.has-border-black {
  border: 1px solid $black;
}

hr {
  height: 2px;
  color: $grey;
  background-color: $grey;
  border: none;
}

ul {
  list-style-type: disc;
}

ul,
ol {
  list-style-position: inside;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 0.5rem;
}

.radio {
  // display: block;
  margin-left: 0;
  margin-right: 1.5rem;
  margin-bottom: 0.75rem;
}

.radio + .radio {
  margin-left: 0;
}

label.label:nth-of-type(1n + 1) {
  margin-top: 1.75rem;
}

body {
  min-height: 100vh;
  background-color: $white;
}

.is-vertically-centered {
  display: flex;
  align-items: center;
}

.button + .button {
  margin-left: 10px;
}

.is-unstyled-button {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.td-sm {
  width: 75px;
}

.td-md {
  width: 160px;
}

.td-lg {
  width: 250px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.overflow-container {
  overflow: auto;
}

.overflow-container-mobile {
  @media (max-width: 400px) {
    overflow: auto;
  }
}

//container helper classes
.is-max-width-large {
  max-width: 1152px !important;
}
.is-max-width-medium {
  max-width: 960px !important;
}
.is-max-width-small {
  max-width: 720px !important;
}
.is-max-width-xs {
  max-width: 528px !important;
}
.is-max-width-xxs {
  max-width: 375px !important;
}
.is-full-width {
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera - hide arrows on numerical fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox - hide arrows on numerical fields */
input[type="number"] {
  -moz-appearance: textfield;
}

.is-underline,
.is-underlined {
  text-decoration: underline;
}

.is-pointer {
  cursor: pointer;
}

span.dropdown-item {
  cursor: pointer;
}

span.dropdown-item:hover {
  color: $dark;
}

.is-top-z-index {
  z-index: 9999 !important;
}

ul.is-flex-ul {
  list-style: none;
}

ul.is-flex-ul li {
  display: flex;
  line-height: 1.5;
}

ul.is-flex-ul li::before {
  content: "•";
  padding-top: 0.1em;
  margin-right: 0.5em;
}

.has-border-grey {
  border: 1px solid $grey;
}

.is-highlighted-field {
  background: $highlighter;
  border: 0;
  border-bottom: 1px solid #000000;
  color: #000000 !important;
  padding: 0.25em 0.33em 0.33em;
}

.is-highlighted-field.warning {
  background: $danger-lighter;
}

[data-lpignore] + [data-lastpass-icon-root] {
  display: none;
}

.highlighter {
  animation: fadeoutBg 5s; /***Transition delay 3s fadeout is class***/
  -moz-animation: fadeoutBg 5s; /* Firefox */
  -webkit-animation: fadeoutBg 5s; /* Safari and Chrome */
  -o-animation: fadeoutBg 5s; /* Opera */
}

@keyframes fadeoutBg {
  from {
    background-color: $success;
  } /** from color **/
  to {
    background-color: transparent;
  } /** to color **/
}

.table.is-rounded {
  border-collapse: inherit;
  th {
    &:first-of-type {
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-top-right-radius: 4px;
    }
  }
  tr:last-of-type {
    td {
      &:first-of-type {
        border-bottom-left-radius: 4px;
      }
      &:last-of-type {
        border-bottom-right-radius: 4px;
      }
    }
  }
}