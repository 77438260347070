/* New color palette */
// https://www.figma.com/file/bkQUgsaC69cAULSgSaajTX/New-website-on-care-dot?type=design&node-id=402-3003&mode=design&t=xhO6rwmsoWksbOCY-0
$black: #1a1a1a;
$black-100: $black;
$black-90: #313131;
$black-80: #484848;
$black-70: #5f5f5f;
$black-60: #767676;
$black-50: #8c8c8c;
$black-40: #a3a3a3;
$black-30: #bababa;
$black-20: #d1d1d1;
$black-10: #e8e8e8;
$black-5: #f4f4f4;
$primary: #3859ff;
$primary-100: $primary;
$primary-90: #4b69fe;
$primary-80: #5e78fd;
$primary-70: #7188fc;
$primary-60: #8497fb;
$primary-50: #96a7fa;
$primary-40: #a9b7f9;
$primary-30: #bcc6f8;
$primary-20: #cfd6f7;
$primary-10: #e2e5f6;
$primary-5: #f5f7ff;
$putty: #fff2e6;
$putty-100: $putty;
$putty-90: #fef3e8;
$putty-80: #fdf3e9;
$putty-70: #fcf3eb;
$putty-60: #fff7f0;
$putty-50: #fff9f3;
$putty-40: #fffaf5;
$putty-30: #fffbf7;
$putty-20: #fffcfa;
$putty-10: #fffefc;
$putty-5: #fffefe;
$light-blue: #e3eef6;
$light-blue-100: $light-blue;
$light-blue-90: #e6f0f7;
$light-blue-80: #e9f1f8;
$light-blue-70: #ebf3f9;
$light-blue-60: #eef5fa;
$light-blue-50: #f1f7fb;
$light-blue-40: #f4f8fb;
$light-blue-30: #f7fafc;
$light-blue-20: #f9fcfd;
$light-blue-10: #fcfdfe;
$light-blue-5: #fefeff;
$lavender: #e5d4ed;
$lavender-100: $lavender;
$lavender-90: #e8d8ef;
$lavender-80: #eaddf1;
$lavender-70: #ede1f2;
$lavender-60: #efe5f4;
$lavender-50: #f2e9f6;
$lavender-40: #f5eef8;
$lavender-30: #f7f2fa;
$lavender-20: #faf6fb;
$lavender-10: #fcfbfd;
$lavender-5: #fefdfe;
$neon-green: #e0fe68;
$neon-green-100: $neon-green;
$neon-green-90: #e3fe77;
$neon-green-80: #e6fe86;
$neon-green-70: #e9fe95;
$neon-green-60: #ecfea4;
$neon-green-50: #efffb3;
$neon-green-40: #f3ffc3;
$neon-green-30: #f6ffd2;
$neon-green-20: #f9ffe1;
$neon-green-10: #fcfff0;
$neon-green-5: #fdfff7;
$navy: #292f53;
$navy-100: $navy;
$navy-90: #3e4464;
$navy-80: #545975;
$navy-70: #696d87;
$navy-60: #7f8298;
$navy-50: #9497a9;
$navy-40: #a9acba;
$navy-30: #bfc1cb;
$navy-20: #d4d5dd;
$navy-10: #eaeaee;
$navy-5: #f4f5f6;
$danger: #fb2525; // original #dc0000
$danger-lighter: #ffd9d9;
$danger-lightest: #ffefef;
$success: #64c661;
$success-lighter: #f0fff0;
$severity-none: #fffbf8;
$severity-mild: #fedbc2;
$severity-moderate: #ffbfaf;
$severity-severe: #ff9c82;
$severity-most-severe: #f97ff3;
$warning: #ffe9bf; // original #ffe08a (not called $warning-darker in the old palette)
$white: #ffffff;
$mint: #d6e2d6; // original
$highlighter: #f6f171;
/**/

/* Old color palette */
$background: #f9f9f9;
$border: #c6c6c6;
$dark: #2c424c;
$gradient-end: $dark;
$gradient-start: #477871;
$grey-darker: #535353;
$grey-dark: #546972;
$grey-semi-dark: #8c8c8c;
$grey: #dde1de;
$grey-light: #f9f9f9;
$hr-background-color: #c6c6c6;
$light: #eff5f3;
$primary-dark: #d1d9ff;
$primary-light: #eff2fe;
$scheme-main: #ffffff;
$success: #64c661;
$warning-darker: #ffe08a;
/**/

:export {
  /* New color palette */
  black: $black;
  black100: $black-100;
  black90: $black-90;
  black80: $black-80;
  black70: $black-70;
  black60: $black-60;
  black50: $black-50;
  black40: $black-40;
  black30: $black-30;
  black20: $black-20;
  black10: $black-10;
  black5: $black-5;
  primary: $primary;
  primary100: $primary-100;
  primary90: $primary-90;
  primary80: $primary-80;
  primary70: $primary-70;
  primary60: $primary-60;
  primary50: $primary-50;
  primary40: $primary-40;
  primary30: $primary-30;
  primary20: $primary-20;
  primary10: $primary-10;
  primary5: $primary-5;
  putty: $putty;
  putty100: $putty-100;
  putty90: $putty-90;
  putty80: $putty-80;
  putty70: $putty-70;
  putty60: $putty-60;
  putty50: $putty-50;
  putty40: $putty-40;
  putty30: $putty-30;
  putty20: $putty-20;
  putty10: $putty-10;
  putty5: $putty-5;
  lightBlue: $light-blue;
  lightBlue100: $light-blue-100;
  lightBlue90: $light-blue-90;
  lightBlue80: $light-blue-80;
  lightBlue70: $light-blue-70;
  lightBlue60: $light-blue-60;
  lightBlue50: $light-blue-50;
  lightBlue40: $light-blue-40;
  lightBlue30: $light-blue-30;
  lightBlue20: $light-blue-20;
  lightBlue10: $light-blue-10;
  lightBlue5: $light-blue-5;
  lavender: $lavender;
  lavender100: $lavender-100;
  lavender90: $lavender-90;
  lavender80: $lavender-80;
  lavender70: $lavender-70;
  lavender60: $lavender-60;
  lavender50: $lavender-50;
  lavender40: $lavender-40;
  lavender30: $lavender-30;
  lavender20: $lavender-20;
  lavender10: $lavender-10;
  lavender5: $lavender-5;
  neonGreen: $neon-green;
  neonGreen100: $neon-green-100;
  neonGreen90: $neon-green-90;
  neonGreen80: $neon-green-80;
  neonGreen70: $neon-green-70;
  neonGreen60: $neon-green-60;
  neonGreen50: $neon-green-50;
  neonGreen40: $neon-green-40;
  neonGreen30: $neon-green-30;
  neonGreen20: $neon-green-20;
  neonGreen10: $neon-green-10;
  neonGreen5: $neon-green-5;
  navy: $navy;
  navy100: $navy-100;
  navy90: $navy-90;
  navy80: $navy-80;
  navy70: $navy-70;
  navy60: $navy-60;
  navy50: $navy-50;
  navy40: $navy-40;
  navy30: $navy-30;
  navy20: $navy-20;
  navy10: $navy-10;
  navy5: $navy-5;
  danger: $danger;
  dangerLighter: $danger-lighter;
  dangerLightest: $danger-lightest;
  success: $success;
  successLighter: $success-lighter;
  severityNone: $severity-none;
  severityMild: $severity-mild;
  severityModerate: $severity-moderate;
  severitySevere: $severity-severe;
  severityMostSevere: $severity-most-severe;
  warning: $warning;
  white: $white;
  mint: $mint;
  highlighter: $highlighter;
  /**/

  /* Old color palette */
  background: $background;
  border: $border;
  dark: $dark;
  gradientEnd: $gradient-end;
  gradientStart: $gradient-start;
  grey: $grey;
  greyDark: $grey-dark;
  greyDarker: $grey-darker;
  greyLight: $grey-light;
  greySemiDark: $grey-semi-dark;
  hrBackgroundColor: $hr-background-color;
  light: $light;
  primaryDark: $primary-dark;
  primaryLight: $primary-light;
  schemeMain: $scheme-main;
  success: $success;
  warningDarker: $warning-darker;
  /**/
}
